const { createApp, ref, computed, watch, nextTick, onMounted } = Vue;
window.addEventListener('load', (event) => {});
createApp({
	setup() {
		const locale = document.querySelector('html').getAttribute('lang');

		const videoPlayer = ref();
		demotest.questions = demotest.questions.map((x) => ({
			...x,
			answered: false,
			correct: null,
			value: null
		}));
		const test = ref(demotest);

		const currentQuestionIndex = ref(0);
		const testStatus = ref('ready');
		const question = computed(() => {
			return test.value.questions[currentQuestionIndex.value];
		});
		const testStart = () => {
			testStatus.value = 'process';
			nextTick(() => {
				initVideo();
			});
		};
		const getAnswerClass = (index) => {
			const { correct, value, answer, answered } = question.value;
			return {
				'bg-success-subtle':
					(correct === true && value === index + 1) ||
					(correct === false && answer === index + 1),
				'bg-danger-subtle': correct === false && value === index + 1,
				'test-answer-shadow':
					!answered && testStatus.value !== 'browse',
				'border-secondary': value === index + 1,
				'border-transparent ': value !== index + 1
			};
		};
		const goPrevQuestion = (q = false) => {
			q === false && (q = currentQuestionIndex.value);
			q = q === 0 ? test.value.questions.length - 1 : q - 1;
			if (
				test.value.questions.filter((answer) => answer.answered)
					.length === test.value.questions.length
			) {
				return;
			}
			if (test.value.questions[q].answered) {
				goPrevQuestion(q);
			} else {
				currentQuestionIndex.value = q;
			}
		};
		const goNextQuestion = (q = false) => {
			q === false && (q = currentQuestionIndex.value);
			q = q === test.value.questions.length - 1 ? 0 : q + 1;
			if (
				test.value.questions.filter((answer) => answer.answered)
					.length === test.value.questions.length
			) {
				return;
			}
			if (test.value.questions[q].answered) {
				goNextQuestion(q);
			} else {
				currentQuestionIndex.value = q;
			}
		};
		const submitAnswer = () => {
			const currentQuestion =
				test.value.questions[currentQuestionIndex.value];
			if (currentQuestion.value) {
				if (currentQuestion.value === currentQuestion.answer) {
					currentQuestion.correct = true;
				} else {
					currentQuestion.correct = false;
				}
				currentQuestion.answered = true;
				const incorrect = test.value.questions.filter(
					(x) => x.correct === false
				).length;
				const answered = test.value.questions.filter(
					(x) => x.answered
				).length;
				if (answered >= test.value.questions.length) {
					if (incorrect >= 4) {
						testFinish('failed');
					} else {
						testFinish('passed');
					}
				} else {
					setTimeout(() => {
						goNextQuestion();
					}, 800);
				}
			}
		};
		const testFinish = (action, params) => {
			const unanswered = test.value.questions.filter(
				(x) => x.answered === false
			);
			unanswered.forEach((x) => (x.correct = false));
			switch (action) {
				case 'failed':
					testStatus.value = 'finish';
					testResult.value.status = 'failed';
					break;
				case 'passed':
					testStatus.value = 'finish';
					testResult.value.status = 'passed';
					break;
				default:
					break;
			}
		};
		const testResult = ref({
			status: null
		});
		const repeatDemoTest = () => {
			test.value.questions.forEach((x) => {
				x.answered = false;
				x.correct = null;
				x.value = null;
			});
			currentQuestionIndex.value = 0;
			testStart();
		};
		const hasImage = computed(() => question.value.picture !== null);
		const hasVideo = computed(() => question.value.video !== null);
		const questionVideo = ref(null);
		const initVideo = () => {
			videoPlayer.value && videoPlayer.value.dispose();
			let video = document.createElement('video');
			video.classList.add('video-js');
			video.setAttribute('playsinline', true);
			questionVideo.value.append(video);
			videoPlayer.value = videojs(
				questionVideo.value.querySelector('.video-js'),
				{
					autoplay: true,
					muted: true,
					controls: true,
					loop: false,
					fill: true,
					responsive: true,
					breakpoints: {
						tiny: 900
					},
					controlBar: {
						volumePanel: false,
						muteToggle: false
					},
					sources: [
						{
							withCredentials: false,
							type: 'application/x-mpegURL',
							src: question.value.video
						}
					]
				}
			);
		};
		watch(
			() => question.value.video,
			() => {
				nextTick(() => {
					if (
						videoPlayer.value &&
						questionVideo.value &&
						question.value.video
					) {
						initVideo();
					}
				});
			},
			{ immediate: true, deep: true }
		);
		const navSlider = ref(null);
		const setSlide = (index) => {
			navSlider.value.swiper.slideTo(index);
		};
		watch(
			() => currentQuestionIndex.value,
			(newVal) => {
				setSlide(newVal);
			}
		);
		const mediaContent = ref(null);
		onMounted(() => {
			mediaContent.value.addEventListener('contextmenu', (e) =>
				e.preventDefault()
			);
			const touchableElement = document.querySelector('.test-body');
			touchableElement.addEventListener(
				'touchstart',
				(event) => {
					touchstartX = event.changedTouches[0].screenX;
					touchstartY = event.changedTouches[0].screenY;
				},
				false
			);

			touchableElement.addEventListener(
				'touchend',
				(event) => {
					touchendX = event.changedTouches[0].screenX;
					touchendY = event.changedTouches[0].screenY;
					if (['process', 'browse'].includes(testStatus.value)) {
						handleGesture();
					}
				},
				false
			);
			document.addEventListener('keyup', handleKeyUp);
			testStart();
		});

		const handleGesture = () => {
			if (touchendX < touchstartX) {
				goNextQuestion();
			}

			if (touchendX > touchstartX) {
				goPrevQuestion();
			}
		};
		const handleKeyUp = (e) => {
			if (['process', 'browse'].includes(testStatus.value)) {
				if (e.key === 'ArrowLeft') {
					goPrevQuestion();
				}
				if (e.key === 'ArrowRight') {
					goNextQuestion();
				}
				/*if (e.key === 'Enter') {
					if (
						test.questions[currentQuestionIndex.value].value !==
						null
					) {
						submitAnswer();
					}
				}*/
				if (
					['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(
						e.key
					)
				) {
					const number = Number(e.key);
					if (
						test.value.questions[currentQuestionIndex.value]
							.options[locale].length >= number &&
						testStatus.value === 'process' &&
						!test.value.questions[currentQuestionIndex.value]
							.answered
					) {
						test.value.questions[currentQuestionIndex.value].value =
							number;
						submitAnswer();
					}
				}
			}
		};
		const correctAnswers = computed(() => {
			return test.value.questions.filter((x) => x.answered && x.correct);
		});
		return {
			currentQuestionIndex,
			testStatus,
			testResult,
			test,
			testStart,
			hasImage,
			hasVideo,
			question,
			locale,
			goPrevQuestion,
			goNextQuestion,
			questionVideo,
			getAnswerClass,
			submitAnswer,
			navSlider,
			correctAnswers,
			repeatDemoTest,
			mediaContent
		};
	}
}).mount('#demotest');
